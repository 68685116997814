//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { defineComponent, useRoute, ref, computed } from '@nuxtjs/composition-api'
import useExternalLink from '~/composables/useExternalLink'

export default defineComponent({
  props: {
    label: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    }
  },
  setup (props) {
    const route = useRoute()
    const open = ref(false)
    const hover = ref(false)
    const { displayLink } = useExternalLink(props.link)

    const active = computed(() => {
      const currentPath = route.value.path
      const targetPath = props.link.split('?')[0]
      return currentPath === targetPath
    })

    return {
      open,
      hover,
      active,
      displayLink
    }
  }
})
